import { useEffect, useRef, useState } from "react";
import { useTaskCreation } from "../../../hooks/useTaskCreation";
import { DropDownFilterRefProps, DropDownSelect } from "../../Filters/DropDown/Index";
import { IOptionsProps } from "../../../@types/reactSelect/IOption";
import axios from "../../../services/axios"
import { MessageBalloon } from "../../MessageBalloon/Index";
import { useForm } from "react-hook-form";
import { CounterInputEdit } from "../../EditOnboarding/components/Input/CounterInput/Index";

interface BagReceiveProps { }

export const BagReceive: React.FC<BagReceiveProps> = () => {
  const [optionsProducts, setOptionsProducts] = useState<IOptionsProps>([]);
  const [classificationSelected, setClassificationSelected] = useState<string | undefined>(undefined);
  const [productSelected, setProductSelected] = useState<string | undefined>(undefined);
  const [quantity, setQuantity] = useState(0);
  const { control } = useForm();
  const productRef = useRef<DropDownFilterRefProps>();

  const {
    payload,
    setPayload,
    setAllowTaskCreation,
    responsibleId,
    farmId,
  } = useTaskCreation();

  useEffect(() => {
    axios.get("/products")
      .then(({ data }) => {
        setOptionsProducts(data.map((el: any) =>
        ({
          label: el.trade_name,
          value: el
        })))
      })
  }, []);

  useEffect(() => {
    setProductSelected(undefined)
    if (productRef && productRef.current)
      productRef.current.resetDropDown();
    setAllowTaskCreation(false)
  }, [classificationSelected]);

  useEffect(() => {
    if (quantity == 0) {
      setAllowTaskCreation(false)
    } else {
      setValuesOnPayload()
    }
  }, [quantity]);

  function setValuesOnPayload() {
    if (productSelected)
      setPayload({
        ...payload,
        changes: [
          {
            farm_id: farmId,
            assigned_user_id: responsibleId,
            product_id: (productSelected as any)?.id,
            quantity_ordered: quantity
          }
        ]
      });
    setAllowTaskCreation(true)
  }

  const [mapTypes] = useState(new Map());
  const [optionTypes, setOptionTypes] = useState<IOptionsProps>([]);

  useEffect(() => {
    optionsProducts.map((el) => mapTypes.set((el?.value as any)?.classification, (el?.value as any)?.classification))
    setOptionTypes(Array.from(mapTypes.keys()).map(el => ({
      label: el,
      value: el
    })))
  }, [optionsProducts]);

  return (
    <>
      <div className="balloon-container">
        <MessageBalloon
          text="Você deve criar uma tarefa por produto, você pode
          cadastrar mais produtos em “Gerenciar sistema”."
          pointed
        />
      </div>
      <DropDownSelect
        options={optionTypes}
        placeholder="Selecionar classificação do produto..."
        onChange={(ev) => setClassificationSelected(ev)}
      />

      {classificationSelected &&
        <>
          <DropDownSelect
            ref={productRef}
            options={optionsProducts.filter(el => (el?.value as any)?.classification == classificationSelected)}
            placeholder="Selecionar produto..."
            onChange={(ev) => setProductSelected(ev)}
          />

          {productSelected &&
            <>
              <div>
                <div style={{ height: 10 }} />
                <span className="task-label">Resumo</span>

                <div className="body_resume">
                  <div className="formContainerProduct">
                    <label className="body_title">
                      Nome comercial
                    </label>
                    <label className="container_formResult">
                      {(productSelected as any)?.trade_name}
                    </label>
                  </div>
                  <div className="formContainerProduct">
                    <label className="body_title">
                      Fabricante
                    </label>
                    <label className="container_formResult">
                      {(productSelected as any)?.manufacturer}
                    </label>
                  </div>
                  <div className="formContainerProduct">
                    <label className="body_title">
                      Consumo
                    </label>
                    <label className="container_formResult">
                      {`${(productSelected as any)?.expected_consumption} g/kg de PV`}
                    </label>
                  </div>
                  <div className="formContainerProduct">
                    <label className="body_title">
                      Frequência
                    </label>
                    <label className="container_formResult last">
                      {`${(productSelected as any)?.supply_frequency} x por semana`}
                    </label>
                  </div>
                </div>
              </div>

              <div className="balloon-container">
                <MessageBalloon
                  text="Qual a quantidade em quilo do produto?"
                  pointed
                />
              </div>

              <CounterInputEdit
                defaultValue={0}
                control={control}
                label="Kg"
                max={99999999}
                onChangevalue={(ev) => {
                  setQuantity(ev)
                }}
                isInline
              />
            </>
          }
        </>
      }
    </>
  );
}