import { useEditOnboarding } from "../../hooks/useEditOnboarding";
import { Card } from "../Card/Index";
import ChipPressableComponent from "../Chips/ComponentChipPressable/Index";
import ComponentInputSearch from "../InputSearch";
import Pagination from "../Pagination";
import "./styles.css";
import { useFilter } from "../../hooks/useFilter";
import { ReactNode } from "react";
import { FooterEditOnboarding } from "../EditOnboarding/Footer";

interface ViewCardsProps {
  children?: ReactNode;
  searchable?: boolean;
  disabledSubmit?: boolean;
  hideControllsSubmit?: boolean;
}

export function ViewCards({ children, searchable = true, disabledSubmit = false, hideControllsSubmit = false }: ViewCardsProps) {
  const {
    filterSelected,
    setFilterSelected,
    listCards,
    setOptionForm,
    totalPages,
    currentPage,
    setIdSelected,
    setCurrentPage,
    setQueryToSearch,
  } = useEditOnboarding();

  const { systemManager } = useFilter();

  const OptionsMap = new Map();
  OptionsMap.set("farms", "Agropecuária ou Fazenda");
  OptionsMap.set("areas", "Retiro");
  OptionsMap.set("micro-areas", "Pasto");
  OptionsMap.set("batches", "Lote");

  return (
    <>
      {/* Filtro */}
      <div className="filter__content">
        {systemManager == "PROPERTIES_AREAS" && (
          <>
            <ChipPressableComponent
              theme={filterSelected == "farms" ? "menu-dark" : "menu-light"}
              label={"Agropecuária ou Fazenda"}
              onClick={() => setFilterSelected("farms")}
            />
            <ChipPressableComponent
              theme={filterSelected == "areas" ? "menu-dark" : "menu-light"}
              label={"Retiro"}
              onClick={() => setFilterSelected("areas")}
            />
            <ChipPressableComponent
              theme={
                filterSelected == "micro-areas" ? "menu-dark" : "menu-light"
              }
              label={"Pasto"}
              onClick={() => setFilterSelected("micro-areas")}
            />
          </>
        )}
        {systemManager == "CREATE_BATCH" && (
          <>
            <ChipPressableComponent
              theme={filterSelected == "batches" ? "menu-dark" : "menu-light"}
              label={"Lote"}
              onClick={() => setFilterSelected("batches")}
            />
          </>
        )}

        {systemManager == "CATTLE_MAP" && (
          <>
            <ChipPressableComponent
              theme={filterSelected == "animal_category" ? "menu-dark" : "menu-light"}
              label={"Categoria Animal"}
              onClick={() => setFilterSelected("animal_category")}
            />

            <ChipPressableComponent
              theme={filterSelected == "goals_birth" ? "menu-dark" : "menu-light"}
              label={"Meta de Nascimento"}
              onClick={() => setFilterSelected('goals_birth')}
            />

            <ChipPressableComponent
              theme={filterSelected == "goals_death" ? "menu-dark" : "menu-light"}
              label={"Meta de Morte"}
              onClick={() => setFilterSelected('goals_death')}
            />
          </>
        )}
        {searchable && <>
          <div style={{ width: "100%" }}>
            <ComponentInputSearch
              type={OptionsMap.get(filterSelected)}
              onClick={(query: string | undefined) => setQueryToSearch(query)}
            />
          </div>
        </>}
      </div>
      {/* Filtro */}

      {!!children ? <>
        <div className="children__content">
          {children}
        </div>
        {!hideControllsSubmit && <>
          <FooterEditOnboarding
            undoFunction={() => { }}
            saveFunction={() => { }}
            isNew={false}
            disabledSubmit={disabledSubmit}
          />
        </>}
      </> : <>
        {/* Visualização dos cards */}
        <div className="grid__infos">
          {listCards.map((card) => (
            <Card
              type={filterSelected}
              initials={card.initial}
              name={card.name}
              qtdArea={card.qtdArea}
              qtdMicroArea={card.qtdMicroArea}
              qtdAnimals={card.qtdAnimals}
              initialBatch={card.initialBatch}
              onClick={() => {
                if (filterSelected == "areas") setOptionForm(filterSelected)
                setIdSelected(card.id);
              }}
            />
          ))}
        </div>
        <div className="delimiter" />
        {/* Visualização dos cards */}

        {/* Paginação */}
        <div className="paginator">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onClick={(value: number) => setCurrentPage(value)}
          />
        </div>
        {/* Paginação */}
      </>}
    </>
  );
}
