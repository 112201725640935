import "./styles.css"
import { useEffect, useState } from "react";
import { ReactComponent as PlusIcon } from "../../../assets/svg/icons/plus.svg";
import { useEditOnboarding } from "../../../hooks/useEditOnboarding";
import TableVisualizationRow from "./TableVisualizationRow/Index";
import { useTaskCreation } from "../../../hooks/useTaskCreation";
import InfoEvaluation from "../../Tasks/CreateEvaluator/InfoEvaluation";
import axios from "../../../services/axios"
import { IOptionsEvaluation } from "../../ViewTables/Index";

interface TableProductsProps {
  listTable: IEvaluation[];
  filter: IOptionsEvaluation | undefined;
}

export default function TableEvaluation({ listTable, filter }: TableProductsProps) {
  const {
    setOptionForm,
  } = useEditOnboarding();
  const { setModalCreateEvaluator, setModalStartEvaluator, modalStartEvaluator } = useTaskCreation();
  const [haveEvaluator, setHaveEvaluator] = useState(false);
  const [startedEvaluation, setStartedEvaluation] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  useEffect(() => {
    axios.get("users/user/evaluator")
      .then(({ data }) => {
        setHaveEvaluator(data.filter((el: any) => el.status == "ACTIVE").length > 0)
      })
    axios.get(`tasks/pasture-evaluation`)
      .then(({ data }) => {
        setStartedEvaluation(data.total_elements > 0)
      })
  }, [, modalStartEvaluator]);

  const statusMap = new Map<string, string>()
  statusMap.set("COMPLETED", "Avaliado")
  statusMap.set("PENDING", "Pendente")
  statusMap.set("ERROR", "Erro")

  return (
    <>
      {!haveEvaluator ?
        <div className="emptyTable_container">
          <span>Para iniciar a avaliação, é necessário alocar um responsável como avaliador.</span>
          <div
            className="addProduct_button"
            onClick={() => {
              setOptionForm("create_evaluator")
              setModalCreateEvaluator(true)
            }}
          >
            <PlusIcon />
            <span>Adicionar avaliador</span>
          </div>
        </div>
        :
        (!startedEvaluation ?
          <div className="emptyTable_container">
            <span>Inicie a avaliação para começar a visualizar os pastos avaliados.</span>
            <div
              className="addProduct_button"
              onClick={() => {
                setModalStartEvaluator(true)
              }}
            >
              <span>Iniciar avaliação</span>
            </div>
          </div> :
          (
            listTable && listTable.length > 0 ?
              <>
                <section className="tableColumnEvaluation">
                  <div>Usuário</div>
                  <div>Pasto</div>
                  <div>Status</div>
                  <div>Data Criação</div>
                  <div>Data Execução</div>
                  <div />
                </section>

                <section className="tableViewRows">
                  {listTable.map(evaluation =>
                    <TableVisualizationRow evaluation={evaluation} setSelectedId={setSelectedId} />
                  )}
                </section>
              </>
              :
              <div className="emptyTable_container">
                {filter ? <span>Nenhum resultado encontrado para o estado {statusMap.get(filter)}.</span> : <span> Nenhum resultado encontrado.</span>}
              </div>
          ))
      }
      <InfoEvaluation
        selected={selectedId}
        setSelectedId={setSelectedId}
      />
    </>
  )
}