import { useEffect, useState } from "react";
import ReactSelect, { Props as SelectProps } from "react-select";
import { ReactComponent as ChevronIcon } from "../../../../assets/svg/icons/chevronDown.svg";
import {
  IOptionProps,
  IOptionsProps,
} from "../../../../@types/reactSelect/IOption";
import { Controller } from "react-hook-form";
import "./styles.css";
import { deepFind } from "../../../../utils/objects/deepFind";

interface ReactSelectProps extends SelectProps<any> {
  name: string;
  getSelectedData?: (data: IOptionProps, action?: string) => void;
  control: any;
  required?: boolean;
  label?: string;
  placeholderText?: string;
  isSearchable?: boolean;
  errorText?: any;
}

const mHeight = "300px";
const speed = "300ms";

export function Dropdown({
  name,
  options,
  getSelectedData,
  control,
  required = false,
  label,
  placeholderText,
  errorText,
  isSearchable = false,
  ...rest
}: ReactSelectProps) {
  const [open, setOpen] = useState(rest.menuIsOpen);
  const [selectOptions, setSelectOption] = useState<IOptionsProps>([]);
  const [upData, setUpData] = useState<boolean>(false);
  const [fieldError, setFieldError] = useState<string | null>(null);

  const selectStyles = () => ({
    option: (provided: any, state: any) => ({
      ...provided,
      position: "relative",
      background: "transparent",
      color: state.isSelected ? "#4D4D4D" : "#999999",
      textTransform: "captilize",
      fontWeight: "bold",
      opacity: open ? 1 : 0.5,
      transition: `all ${speed} ease-in-out`,
      padding: "0",
      paddingBottom: "20px",
      paddingLeft: "12px",
      "&:hover": {
        background: "transparent",
        color: "#4D4D4D",
        cursor: "pointer",
      },
      "&:last-child": {
        paddingBottom: "0",
      },
    }),
    control: (provided: any, { isDisabled }: any) => ({
      ...provided,
      borderRadius: !open ? "9px" : "9px 9px 0px 0px",
      background: rest.isDisabled ? "#F1F1F1" : "#FFF",
      outline: "none",
      minHeight: "56px",
      boxShadow: "none",
      border: `1px solid ${(errorText || !!fieldError) ? "#ff6161" : "#e5e5e5"} !important`,
      fontSize: 18,
      transition: `all ${speed} ease-in-out`,
      zIndex: 0,
      paddingLeft: "20px",
      paddingRight: "20px",
      cursor: isDisabled ? "not-allowed" : "default",
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: "0px 0px 9px 9px",
      outline: "none",
      boxShadow: "none",
      borderLeft: `1px solid ${(errorText || !!fieldError) ? "#ff6161" : "#e5e5e5"} !important`,
      borderRight: `1px solid ${(errorText || !!fieldError) ? "#ff6161" : "#e5e5e5"} !important`,
      borderBottom: `1px solid ${(errorText || !!fieldError) ? "#ff6161" : "#e5e5e5"} !important`,
      position: "relative",
      marginTop: "-10px",
      paddingTop: "10px",
      paddingLeft: "12px",
      paddingRight: "12px",
      marginBottom: "0px",
      fontSize: 18,
      maxHeight: open ? mHeight : "0px",
      overflow: "hidden",
      opacity: open ? 1 : 0.5,
      transition: `all ${speed} ease-in-out`,
      visibility: open ? "visible" : "hidden",
      animation: open
        ? `ease selectOpen ${speed} forwards`
        : `ease selectClose ${speed} forwards`,
      zIndex: 0,
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: "270px",
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      transition: "ease-in-out 400ms",
      transform: open ? "rotateZ(0deg)" : "rotateZ(180deg)",
    }),
    input: (defaultStyles: any) => ({
      ...defaultStyles,
      margin: 0,
      padding: 0,
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: 0,
    }),
    noOptionsMessage: (defaultStyles: any) => ({
      ...defaultStyles,
      with: "100%",
      padding: "12px 0",
      margin: "0px",
    }),
  });

  useEffect(() => {
    if (rest.defaultMenuIsOpen) {
      setOpen(rest.defaultMenuIsOpen);
    }
  }, [rest.defaultMenuIsOpen]);

  useEffect(() => {
    if (options && options.length) {
      setSelectOption(() =>
        options.map((option: IOptionProps) => ({
          label: String(option?.label),
          value: String(option?.value),
          info: option?.info,
        }))
      );
      setUpData(!upData);
    } else {
      setSelectOption([]);
    }
  }, [options]);

  useEffect(() => {
    if (rest.isDisabled) {
      setOpen(false);
    }
  }, [rest.isDisabled]);

  return (
    <>
      <div
        className={"dropdown__container"}
        onClick={() => {
          if (!rest.isDisabled) setOpen((prevState) => !prevState);
        }}
      >
        {label && <label>{label}</label>}
        <Controller
          name={name}
          control={control}
          render={({ field, formState: { errors } }) => {
            if (name.split('.').length > 1) {
              const error = deepFind(errors, name)
              setFieldError(!!error ? error.message as string : null)
            } else setFieldError(!!errors[name] ? errors[name]?.message as string : null)
            return (
              <ReactSelect
                {...rest}
                className={`react-select ${rest.className} ${errorText || !!fieldError ? "dropdown__container--invalid" : ""}`}
                options={selectOptions}
                menuIsOpen={true}
                defaultMenuIsOpen={open}
                styles={selectStyles()}
                components={{
                  ...rest.components,
                  ClearIndicator: () => null,
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => <ChevronIcon />,
                }}
                placeholder={placeholderText}
                isSearchable={isSearchable}
                {...field}
                onChange={(data, { action }) => {
                  getSelectedData && getSelectedData(data, action);
                  if (action) setOpen(rest.closeMenuOnSelect);
                  field.onChange(data);
                }}
              />
            )
          }}
        />
      </div>
      {(errorText || !!fieldError) && (
        <small className="textInput--small-error animation__fadeIn">
          {errorText || fieldError}
        </small>
      )}
    </>
  );
}
