import "../styles.css";
import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as LogoPastejoIcon } from "../../../assets/svg/logo/logoPastejo.svg";
import { splitArray } from "../../../utils/arrays/split";
import { StaticMapRender } from "../../EditOnboarding/components/StaticMapRender/Index";
import Constants from "../../../constants/Index";
import { GoogleMap, Polygon, Polyline } from "@react-google-maps/api";
import { useGoogleMaps } from "../../../hooks/useGoogleMaps";
import { ReactComponent as AreaIcon } from "../../../assets/svg/icons/areaIcon.svg";
import { ReactComponent as LocateIcon } from "../../../assets/svg/icons/point.svg";
import { ICoord } from "../../../@types/GoogleMaps/ICoord";
import { capitalize } from "../../../utils/strings/capitalize";
import { IOptionProps } from "../../../@types/reactSelect/IOption";
import { format } from "date-fns";

interface PDFEvaluationProps {
    farm: IOptionProps;
    areas: IOptionProps[];
    dataReport: IReportMicroAreaPastureEvaluator;
}

interface AvaliacaoPDFProps {
    items?: {
        item: string;
        result: string | undefined;
    }[]
}

export const PDFEvaluation = ({
    farm,
    areas,
    dataReport
}: PDFEvaluationProps) => {
    const [mapPages, setMapPages] = useState(new Map<string, number>());
    const [summaryPages, setSummaryPages] = useState(0);
    const pageRef = useRef(null);
    const [pageHeight, setPageHeight] = useState(0);

    useEffect(() => {
        if (pageRef.current) {
            setPageHeight(1130);
        }
    }, [pageRef]);

    useEffect(() => {
        setSummaryPages(splitArray(Array.from(mapPages.keys()), 30).length)
    }, [mapPages]);

    const mapText = new Map();
    mapText.set("ANUAL_MOLE", "Anual mole")
    mapText.set("FOLHA_LARGA", "Folha larga")
    mapText.set("FOLHA_ESTREITA", "Folha estreita")

    const FooterPDF = ({
        pageSugest,
        light = false,
    }: any) => {
        const [page, setPage] = useState(1);
        const footerRef = useRef(null);

        useEffect(() => {
            if (footerRef.current && !pageSugest) {
                const componentTop = (footerRef.current as HTMLElement)?.offsetTop;
                const currentPage = Math.floor(componentTop / pageHeight);
                setPage(currentPage);
            } else {
                setPage(pageSugest);
            }
        }, [footerRef, pageHeight]);

        return (
            <div className="pdfFooter" id="footer" ref={footerRef}>
                <div className="pdfLogoPastejo">
                    <LogoPastejoIcon />
                </div>
                <span style={{ color: light ? "#FFF" : "#000" }}>
                    {pageSugest ? pageSugest : page}
                </span>
            </div>
        );
    }

    const AvaliacaoPDF = ({
        items
    }: AvaliacaoPDFProps) => {
        return (
            <div className="pdfTableBorder">
                <section className="pdfTableColumn">
                    <div>Itens avaliados</div>
                    <div>Resultado</div>
                </section>
                {items && items.map((item, index) => (
                    <section className="pdfTableViewRows">
                        <div className={`pdfTableRow ${index % 2 === 0 ? "bg_white" : "bg_gray"}`}>
                            <div>{item.item}</div>
                            <div>{capitalize((item.result || "-").toLocaleLowerCase())}</div>
                        </div>
                    </section>
                ))}
            </div>
        );
    }

    const MicroAreaRelatorioPDFpg1 = (microArea: IMicroAreasTaskInfo, index: number, mapCoord: ICoord[]) => {
        const resultadosRef = useRef(null);

        useEffect(() => {
            if (resultadosRef.current) {
                const componentTop = (resultadosRef.current as HTMLElement)?.offsetTop;
                const currentPage = Math.floor(componentTop / pageHeight);
                setMapPages(new Map(mapPages.set(`${index} ${microArea.micro_area_details.micro_area_initials}`, currentPage - 1)))
                setMapPages(new Map(mapPages.set(`${index}.1 Resultados da avaliação de pastagem por pasto`, currentPage - 1)))
            }
        }, [, pageHeight]);

        return (
            <div>
                <div className="pdfTopicContainer" >
                    <h2 className="pdfMicroArea" style={{ width: 45 }}>{index}</h2>
                    <h2 className="pdfMicroArea" ref={resultadosRef}>{microArea.micro_area_details.micro_area_initials}</h2>
                </div>

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.1</h2>
                    <h2 className="pdfTopic">Resultados da avaliação de pastagem por pasto</h2>
                </div>


                <div className="pdfContainerResult">
                    {/* Tabela com detalhes da avaliação */}
                    <div className="pdfTableBorder" style={{ width: "70%" }}>
                        <section className="pdfTableColumn">
                            <div>Fazenda</div>
                            <div>Retiro</div>
                        </section>
                        <section className="pdfTableViewRows">
                            <div className="pdfTableRow">
                                <div>{microArea.micro_area_details.farm}</div>
                                <div>{microArea.micro_area_details.area}</div>
                            </div>
                        </section>

                        <section className="pdfTableColumn">
                            <div>Pasto</div>
                            <div>Área útil do pasto</div>
                        </section>
                        <section className="pdfTableViewRows">
                            <div className="pdfTableRow">
                                <div>{microArea.micro_area_details.micro_area_initials}</div>
                                <div>{microArea.micro_area_details.useful_area}</div>
                            </div>
                        </section>

                        <section className="pdfTableColumn">
                            <div>Avaliador</div>
                            <div>Dia da avaliação</div>
                        </section>
                        <section className="pdfTableViewRows">
                            <div className="pdfTableRow">
                                <div>{microArea.micro_area_details.evaluator_name}</div>
                                <div>{microArea?.micro_area_details?.evaluate_date?format(new Date(microArea?.micro_area_details?.evaluate_date || ""), `dd/MM/yyyy - hh:mm`):"-"}</div>
                            </div>
                        </section>
                    </div>

                    <div className="mapContainerInfoEvaluation pdfContainerResultMap">
                        <StaticMapRender
                            farm={[]}
                            mainPolygon={mapCoord}
                            microAreas={[]}
                            fitCoords={mapCoord}
                        />
                    </div>
                </div>

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.1.1</h2>
                    <h2 className="pdfTopic">Forrageira</h2>
                </div>
                {/* Tabela com detalhes da Forrageira */}
                <AvaliacaoPDF items={[
                    {
                        item: "Presença de forragem primária",
                        result: microArea?.pasture_evaluation_fodder_content?.fodder_content[0] ? "Sim" : "Não"
                    },
                    {
                        item: "Gênero da forrageira",
                        result: microArea?.pasture_evaluation_fodder_content?.fodder_content[0]?.fodder_details?.fodder_genus
                    },
                    {
                        item: "Espécie da forrageira",
                        result: microArea?.pasture_evaluation_fodder_content?.fodder_content[0]?.fodder_details?.fodder_species
                    },
                    {
                        item: "Proporção da forrageira",
                        result: microArea?.pasture_evaluation_fodder_content?.fodder_content[0]?.proportion ?
                            microArea?.pasture_evaluation_fodder_content?.fodder_content[0]?.proportion + "%"
                            : "-"
                    },
                    {
                        item: "Área efetiva (ha)",
                        result: "-"
                    },
                    {
                        item: "Altura do capim",
                        result: microArea?.pasture_evaluation_fodder_content?.fodder_content[0].height
                    },
                    {
                        item: "Presença da forragem secundária",
                        result: microArea?.pasture_evaluation_fodder_content?.fodder_content[1] ? "Sim" : "Não"
                    },
                    {
                        item: "Gênero da forrageira",
                        result: microArea?.pasture_evaluation_fodder_content?.fodder_content[1]?.fodder_details?.fodder_genus
                    },
                    {
                        item: "Espécie da forrageira",
                        result: microArea?.pasture_evaluation_fodder_content?.fodder_content[1]?.fodder_details?.fodder_species
                    },
                    {
                        item: "Proporção da forrageira",
                        result: microArea.pasture_evaluation_fodder_content?.fodder_content[1]?.proportion ?
                            microArea?.pasture_evaluation_fodder_content?.fodder_content[1]?.proportion + "%"
                            : "-"
                    },
                    {
                        item: "Área efetiva (ha)",
                        result: "-"
                    },
                    {
                        item: "Altura do capim",
                        result: microArea?.pasture_evaluation_fodder_content?.fodder_content[1]?.height
                    },
                    {
                        item: "Presença da forragem terciaria",
                        result: microArea?.pasture_evaluation_fodder_content?.fodder_content[2] ? "Sim" : "Não"
                    },
                    {
                        item: "Gênero da forrageira",
                        result: microArea?.pasture_evaluation_fodder_content?.fodder_content[2]?.fodder_details?.fodder_genus
                    },
                    {
                        item: "Espécie da forrageira",
                        result: microArea?.pasture_evaluation_fodder_content?.fodder_content[2]?.fodder_details?.fodder_species
                    },
                    {
                        item: "Proporção da forrageira",
                        result: microArea.pasture_evaluation_fodder_content?.fodder_content[2]?.proportion ?
                            microArea?.pasture_evaluation_fodder_content?.fodder_content[2]?.proportion + "%"
                            : "-"
                    },
                    {
                        item: "Área efetiva (ha)",
                        result: "-"
                    },
                    {
                        item: "Altura do capim",
                        result: microArea?.pasture_evaluation_fodder_content?.fodder_content[2]?.height
                    },
                ]} />

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.1.2</h2>
                    <h2 className="pdfTopic">Invasoras</h2>
                </div>
                {/* Tabela com detalhes da Invasoras */}
                <AvaliacaoPDF items={[
                    {
                        item: "Presença de invasoras",
                        result: microArea?.pasture_evaluation_invasive_content?.has_invasive ? "Sim" : "Não"
                    },
                    {
                        item: "Tipo de folha",
                        result: mapText.get(microArea?.pasture_evaluation_invasive_content?.leaf_type)
                    }
                ]} />
            </div>
        );
    }

    const MicroAreaRelatorioPDFpg2 = (microArea: IMicroAreasTaskInfo, index: number) => {
        return (
            <div>
                <AvaliacaoPDF items={[
                    {
                        item: "Tipo de invasora",
                        result: mapText.get(microArea?.pasture_evaluation_invasive_content?.invasive_type)
                    },
                    {
                        item: "Cobertura da invasora",
                        result: microArea?.pasture_evaluation_invasive_content?.coverage ?
                            microArea.pasture_evaluation_invasive_content?.coverage + "%"
                            : "-"
                    }
                ]} />

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId" style={{ marginTop: 0 }}>{index}.1.3</h2>
                    <h2 className="pdfTopic" style={{ marginTop: 0 }}>Pragas ou doenças</h2>
                </div>
                {/* Tabela com detalhes da Pragas ou doenças */}
                <AvaliacaoPDF items={[
                    {
                        item: "Presença de praga ou doença",
                        result: microArea?.pasture_evaluation_plague_content?.has_plague ? "Sim" : "Não"
                    },
                    {
                        item: "Tipo da praga ou doença",
                        result: microArea?.pasture_evaluation_plague_content?.plague_content[0].plague_name
                    }
                ]} />

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.1.4</h2>
                    <h2 className="pdfTopic">Aguada</h2>
                </div>
                {/* Tabela com detalhes da Aguada */}
                <AvaliacaoPDF items={[
                    {
                        item: "Presença de aguada",
                        result: microArea?.pasture_evaluation_waterhole_content?.has_waterhole ? "Sim" : "Não"
                    },
                    {
                        item: "Tipo de aguada",
                        result: microArea?.pasture_evaluation_waterhole_content?.structure_type
                    },
                    {
                        item: "Nível de acesso",
                        result: microArea?.pasture_evaluation_waterhole_content?.access_level
                    },
                    {
                        item: "Tamanho da área de acesso",
                        result: microArea?.pasture_evaluation_waterhole_content?.access_area ? `${microArea.pasture_evaluation_waterhole_content?.access_area} metros` : "-"
                    },
                    {
                        item: "Precisa de reparo",
                        result: microArea?.pasture_evaluation_waterhole_content?.has_waterhole ?
                            microArea?.pasture_evaluation_waterhole_content?.needs_repair ? "Sim" : "Não" :
                            "-"
                    },
                    {
                        item: "Tipo de reparo",
                        result: microArea?.pasture_evaluation_waterhole_content?.repair_type
                    }
                ]} />

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.1.5</h2>
                    <h2 className="pdfTopic">Suplemento</h2>
                </div>
                {/* Tabela com detalhes da Suplemento */}
                <AvaliacaoPDF items={[
                    {
                        item: "Presença de depósito de suplemento",
                        result: microArea?.pasture_evaluation_suplement_content?.has_storage ? "Sim" : "Não"
                    },
                    {
                        item: "Capacidade do depósito",
                        result: microArea?.pasture_evaluation_suplement_content?.weight ? `${microArea.pasture_evaluation_suplement_content?.weight} kg` : "-"
                    }
                ]} />

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.1.6</h2>
                    <h2 className="pdfTopic">Cocho</h2>
                </div>
                {/* Tabela com detalhes da Cocho */}
                <AvaliacaoPDF items={[
                    {
                        item: "Presença de cocho",
                        result: microArea?.pasture_evaluation_trough_content?.has_trough ? "Sim" : "Não"
                    },
                    {
                        item: "Coberto",
                        result: microArea?.pasture_evaluation_trough_content?.has_trough ?
                            microArea?.pasture_evaluation_trough_content?.has_coverage ? "Sim" : "Não" :
                            "-"
                    },
                    {
                        item: "Tamanho em comprimento",
                        result: microArea?.pasture_evaluation_trough_content?.length ? `${microArea.pasture_evaluation_trough_content?.length} m` : "-"
                    },
                    {
                        item: "Tamanho em largura",
                        result: microArea?.pasture_evaluation_trough_content?.width ? `${microArea.pasture_evaluation_trough_content?.width} m` : "-"
                    },
                    {
                        item: "Tipo de acesso",
                        result: microArea?.pasture_evaluation_trough_content?.sides ? `${microArea.pasture_evaluation_trough_content?.sides} lados` : "-"
                    },
                    {
                        item: "Capacidade do cocho",
                        result: microArea?.pasture_evaluation_trough_content?.capacity ? `${microArea.pasture_evaluation_trough_content?.capacity} kg` : "-"
                    },
                    {
                        item: "Necessita reparo",
                        result: microArea?.pasture_evaluation_trough_content?.has_trough ?
                            microArea?.pasture_evaluation_trough_content?.need_repair ? "Sim" : "Não" :
                            "-"
                    },
                    {
                        item: "Tipo de reparo",
                        result: microArea?.pasture_evaluation_trough_content?.repair_type
                    }
                ]} />

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.1.7</h2>
                    <h2 className="pdfTopic">Cerca</h2>
                </div>
                {/* Tabela com detalhes da Cerca */}
                <AvaliacaoPDF items={[
                    {
                        item: "Tipo de cerca",
                        result: microArea?.pasture_evaluation_fence_content?.fence_type
                    }
                ]} />
            </div>
        )
    }

    const MicroAreaRelatorioPDFpg3 = (microArea: IMicroAreasTaskInfo, index: number) => {
        const fotosRef = useRef(null);

        useEffect(() => {
            if (fotosRef.current) {
                const componentTop = (fotosRef.current as HTMLElement)?.offsetTop;
                const currentPage = Math.floor(componentTop / pageHeight);
                setMapPages(new Map(mapPages.set(`${index}.2 Fotos`, currentPage - 1)))
            }
        }, [, pageHeight]);

        return (
            <div ref={fotosRef}>
                {/* Tabela com detalhes da Cerca */}
                <AvaliacaoPDF items={[
                    {
                        item: "Tipo de material",
                        result: microArea?.pasture_evaluation_fence_content?.fence_structure
                    },
                    {
                        item: "Quantidade de fios",
                        result: `${microArea?.pasture_evaluation_fence_content?.wire_quantity}`
                    },
                    {
                        item: "Precisa de reparo",
                        result: microArea?.pasture_evaluation_fence_content?.need_repair ? "Sim" : "Não"
                    }
                ]} />

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.1.8</h2>
                    <h2 className="pdfTopic">Relevo e solo</h2>
                </div>
                {/* Tabela com detalhes da Relevo e solo */}
                <AvaliacaoPDF items={[
                    {
                        item: "Relevo e solo",
                        result: microArea?.pasture_evaluation_terrain_content?.terrain_type
                    },
                    {
                        item: "Tipo de solo",
                        result: microArea?.pasture_evaluation_terrain_content?.soil_moisture
                    }
                ]} />

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.2</h2>
                    <h2 className="pdfTopic">Fotos</h2>
                </div>
                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.2.1</h2>
                    <h2 className="pdfTopic">Presença de forragem primária</h2>
                </div>
                <div className="pdfContainerPhoto">
                    {microArea?.pasture_evaluation_fodder_content?.fodder_content[0]?.photo_details?.url ?
                        <img className="pdfPhoto"
                            src={microArea?.pasture_evaluation_fodder_content?.fodder_content[0]?.photo_details?.url || ""}
                            alt="Presença de forragem primária"
                        />
                        :
                        <div className="pdfPhoto" />
                    }
                </div>

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.2.2</h2>
                    <h2 className="pdfTopic">Presença de forragem secundária</h2>
                </div>
                <div className="pdfContainerPhoto">
                    {microArea?.pasture_evaluation_fodder_content?.fodder_content[1]?.photo_details?.url ?
                        <img className="pdfPhoto"
                            src={microArea?.pasture_evaluation_fodder_content?.fodder_content[1]?.photo_details?.url || ""}
                            alt="Presença de forragem secundária"
                        />
                        :
                        <div className="pdfPhoto">
                            Não há registro
                        </div>
                    }
                </div>
            </div>
        );
    }

    const MicroAreaRelatorioPDFpg4 = (microArea: IMicroAreasTaskInfo, index: number) => {
        return (
            <div>
                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.2.3</h2>
                    <h2 className="pdfTopic">Presença de invasoras</h2>
                </div>
                <div className="pdfContainerPhoto">
                    {microArea?.pasture_evaluation_invasive_content?.photo_details?.url ?
                        <img className="pdfPhoto"
                            src={microArea?.pasture_evaluation_invasive_content?.photo_details?.url || ""}
                            alt="Presença de invasoras"
                        />
                        :
                        <div className="pdfPhoto">
                            Não há registro
                        </div>
                    }
                </div>

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.2.4</h2>
                    <h2 className="pdfTopic">Presença de pragas ou doenças</h2>
                </div>
                <div className="pdfContainerPhoto">
                    {microArea?.pasture_evaluation_plague_content?.plague_content[0]?.photo_details?.url ?
                        <img className="pdfPhoto"
                            src={microArea?.pasture_evaluation_plague_content?.plague_content[0]?.photo_details?.url || ""}
                            alt="Presença de pragas ou doenças 1"
                        />
                        :
                        <div className="pdfPhoto">
                            Não há registro
                        </div>
                    }
                    {microArea?.pasture_evaluation_plague_content?.plague_content[1]?.photo_details?.url ?
                        <img className="pdfPhoto"
                            src={microArea?.pasture_evaluation_plague_content?.plague_content[1]?.photo_details?.url || ""}
                            alt="Presença de pragas ou doenças 2"
                        />
                        :
                        <div className="pdfPhoto">
                            Não há registro
                        </div>
                    }
                </div>

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.2.5</h2>
                    <h2 className="pdfTopic">Presença de aguada</h2>
                </div>
                <div className="pdfContainerPhoto">
                    {microArea?.pasture_evaluation_waterhole_content?.photo_details?.url ?
                        <img className="pdfPhoto"
                            src={microArea?.pasture_evaluation_waterhole_content?.photo_details?.url || ""}
                            alt="Presença de aguada"
                        />
                        :
                        <div className="pdfPhoto">
                            Não há registro
                        </div>
                    }
                </div>
            </div>
        );
    }

    const MicroAreaRelatorioPDFpg5 = (microArea: IMicroAreasTaskInfo, index: number) => {
        return (
            <div>
                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.2.6</h2>
                    <h2 className="pdfTopic">Presença de depósito de suplemento</h2>
                </div>
                <div className="pdfContainerPhoto">
                    {microArea?.pasture_evaluation_suplement_content?.photo_details?.url ?
                        <img className="pdfPhoto"
                            src={microArea?.pasture_evaluation_suplement_content?.photo_details?.url || ""}
                            alt="Presença de depósito de suplemento"
                        />
                        :
                        <div className="pdfPhoto">
                            Não há registro
                        </div>
                    }
                </div>

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.2.7</h2>
                    <h2 className="pdfTopic">Presença de cocho</h2>
                </div>
                <div className="pdfContainerPhoto">
                    {microArea?.pasture_evaluation_trough_content?.photo_details?.url ?
                        <img className="pdfPhoto"
                            src={microArea?.pasture_evaluation_trough_content?.photo_details?.url || ""}
                            alt="Presença de cocho"
                        />
                        :
                        <div className="pdfPhoto">
                            Não há registro
                        </div>
                    }
                </div>

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.2.8</h2>
                    <h2 className="pdfTopic">Tipo de cocho</h2>
                </div>
                <div className="pdfContainerPhoto">
                    {microArea?.pasture_evaluation_fence_content?.photo_details?.url ?
                        <img className="pdfPhoto"
                            src={microArea?.pasture_evaluation_fence_content?.photo_details?.url || ""}
                            alt="Tipo de cocho"
                        />
                        :
                        <div className="pdfPhoto">
                            Não há registro
                        </div>
                    }
                </div>
            </div>
        );
    }

    const MicroAreaRelatorioPDFpg6 = (microArea: IMicroAreasTaskInfo, index: number) => {
        return (
            <div>
                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.2.9</h2>
                    <h2 className="pdfTopic">Tipo de relevo</h2>
                </div>
                <div className="pdfContainerPhoto">
                    {microArea?.pasture_evaluation_terrain_content?.photo_details?.url ?
                        <img className="pdfPhoto"
                            src={microArea?.pasture_evaluation_terrain_content?.photo_details?.url || ""}
                            alt="Tipo de relevo"
                        />
                        :
                        <div className="pdfPhoto">
                            Não há registro
                        </div>
                    }
                </div>
            </div >
        );
    }

    const MicroAreaRelatorioMapaPDF = () => {
        const mapRefPosition = useRef(null);
        const [mapref, setMap] = useState<google.maps.Map>();
        const [centerCoords, setCenterCoords] = useState<any>([]);
        const [colorsArea, setColorsArea] = useState(new Map<Number, string>());

        const mapStyles = {
            width: "100%",
            height: "100%",
        } as CSSProperties;

        function getRandomColor() {
            const colors: Array<string> = Object.values(Constants.GRAZINGMAP_COLORS);
            return colors[Math.floor(Math.random() * colors.length)];
        }

        const {
            getBounds
        } = useGoogleMaps();

        const onLoad = useCallback(function callback(map: any) {
            setMap(map);
        }, []);

        useEffect(() => {
            setMapPages(new Map(mapPages.set(`${1} Mapa`, summaryPages + 1)))
            areas.map((area: any) => {
                setColorsArea(new Map(colorsArea.set(Number(area.value), getRandomColor())))
            })
        }, []);

        useEffect(() => {
            if (centerCoords !== undefined && mapref !== undefined) {
                mapref.fitBounds(getBounds(centerCoords))
            }
        }, [centerCoords, mapref])

        useEffect(() => {
            setCenterCoords(farm.info?.map_coords)
        }, [farm]);

        return (
            <div className="pdfPage" style={{ padding: 0, overflow: "hidden" }} ref={mapRefPosition}>
                <div className="pdfPageMap">
                    <div className="pdfContent">
                        <div>
                            <h1>MAPA</h1>
                            <div className="areaContainer">
                                <AreaIcon width={25} height={25} className="whiteColor" />
                                <h1>{farm.info?.initials}</h1>
                                <LocateIcon width={14} height={14} className="greenColor" />
                            </div>
                            <div className="detailareaContainer">
                                <h1>{areas.length > 1 ? `${areas.length} Retiros` : areas[0].label}</h1>
                                <span>{dataReport?.micro_areas_task_info?.length} pasto(s) avaliado(s) (-)</span>
                            </div>
                        </div>
                    </div>

                    <FooterPDF pageSugest={summaryPages + 2} light />
                </div>
                {dataReport?.micro_areas_task_info &&
                    <GoogleMap
                        onTilesLoaded={() => {
                            Promise.all(
                                Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; })))
                                .finally(() => {
                                    window.print()
                                });
                        }}
                        onLoad={onLoad}
                        mapContainerStyle={mapStyles}
                        options={{
                            streetViewControl: false,
                            scaleControl: true,
                            fullscreenControl: false,
                            styles: [
                                {
                                    featureType: "poi.business",
                                    elementType: "labels",
                                    stylers: [
                                        {
                                            visibility: "off",
                                        },
                                    ],
                                },
                            ],
                            gestureHandling: "greedy",
                            disableDoubleClickZoom: true,
                            minZoom: 5,
                            mapTypeControl: true,
                            mapTypeId: google.maps.MapTypeId.SATELLITE,
                            mapTypeControlOptions: {
                                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                                position: google.maps.ControlPosition.BOTTOM_CENTER,
                                mapTypeIds: [
                                    google.maps.MapTypeId.ROADMAP,
                                    google.maps.MapTypeId.SATELLITE,
                                    google.maps.MapTypeId.HYBRID,
                                ],
                            },
                            clickableIcons: false,
                            draggableCursor: "default",
                            draggable: false,
                            zoomControl: false
                        }}
                    >
                        <Polyline
                            options={Constants.POLYLINE_DASHED_GREEN}
                            path={farm.info?.map_coords}
                            draggable={false}
                            editable={false}
                        />

                        {dataReport?.micro_areas_task_info && dataReport?.micro_areas_task_info.length > 0
                            ? dataReport?.micro_areas_task_info
                                .map((polygonMicroArea) =>
                                    <Polygon
                                        options={{
                                            ...Constants.POLYGON_SELECTED,
                                            fillOpacity: 0.5,
                                            fillColor: colorsArea.get(areas.filter(el => el.label == polygonMicroArea.micro_area_details.area)[0].value),
                                        }}
                                        path={polygonMicroArea?.micro_area_details?.micro_area_map_coords || []}
                                        draggable={false}
                                        editable={false}
                                    />
                                )
                            : <></>}
                    </GoogleMap>}
            </div>
        )
    }

    const [mapref, setMap] = useState<google.maps.Map>();
    const [centerCoords, setCenterCoords] = useState<any>([]);
    const {
        getBounds
    } = useGoogleMaps();

    useEffect(() => {
        if (centerCoords !== undefined && mapref !== undefined) {
            mapref.fitBounds(getBounds(centerCoords))
        }
    }, [centerCoords, mapref])

    useEffect(() => {
        setCenterCoords(farm.info?.map_coords)
    }, [farm]);

    return (
        <div className="pdfContainer">
            <div className="pdfPage" style={{ background: "#365951" }} ref={pageRef}>
                <div className="pdfHeader">
                    <div className="pdfHeaderContainer">
                        <h2>{farm?.label}</h2>
                        <h2>{areas && areas?.length > 1 ? `${areas?.length} Retiros` : areas[0]?.label}</h2>
                        <h1>Relatório por pasto</h1>
                    </div>
                    <div className="pdfLogoPastejo">
                        <LogoPastejoIcon />
                    </div>
                </div>
            </div>

            {splitArray(Array.from(mapPages.keys()), 30).map((pg: any) => (
                <div className="pdfSumario">
                    <h2>Sumário</h2>
                    <div>
                        {pg.map((element: any) => (
                            <div className="pdfPageContent">
                                <span className="pdfPageIndice">{element}</span>
                                <span className="pdfPageLine">{".".repeat(315)}</span>
                                <span className="pdfPageNumber">{(mapPages.get(element) || 0) + summaryPages + 1}</span>
                            </div>
                        ))}
                    </div>
                </div>
            ))}

            <div className="pdfPageMapContainer">
                {MicroAreaRelatorioMapaPDF()}
            </div>

            {dataReport.micro_areas_task_info && dataReport.micro_areas_task_info.length > 0 ?
                dataReport?.micro_areas_task_info?.map((microArea, index) => (
                    <>
                        <div className="pdfPage">
                            <div className="pdfContent">
                                {MicroAreaRelatorioPDFpg1(microArea, index + 1, microArea.micro_area_details.micro_area_map_coords)}
                            </div>
                            <FooterPDF />
                        </div>
                        <div className="pdfPage">
                            <div className="pdfContent">
                                {MicroAreaRelatorioPDFpg2(microArea, index + 1)}
                            </div>
                            <FooterPDF />
                        </div>
                        <div className="pdfPage">
                            <div className="pdfContent">
                                {MicroAreaRelatorioPDFpg3(microArea, index + 1)}
                            </div>
                            <FooterPDF />
                        </div>
                        <div className="pdfPage">
                            <div className="pdfContent">
                                {MicroAreaRelatorioPDFpg4(microArea, index + 1)}
                            </div>
                            <FooterPDF />
                        </div>
                        <div className="pdfPage">
                            <div className="pdfContent">
                                {MicroAreaRelatorioPDFpg5(microArea, index + 1)}
                            </div>
                            <FooterPDF />
                        </div>
                        <div className="pdfPage">
                            <div className="pdfContent">
                                {MicroAreaRelatorioPDFpg6(microArea, index + 1)}
                            </div>
                            <FooterPDF />
                        </div>
                    </>
                ))
                : <></>
            }
        </div>
    );
};
