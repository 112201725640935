import "./styles.css";
import { ReactComponent as UsersIcon } from "../../../assets/svg/icons/users.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/svg/icons/calendar.svg";
import { ReactComponent as PlusIcon } from "../../../assets/svg/icons/plus.svg";
import { ReactComponent as BackIcon } from "../../../assets/svg/icons/back.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/svg/icons/download.svg";
import { ViewCards } from "../../../components/ViewCards/Index";
import { useEditOnboarding } from "../../../hooks/useEditOnboarding";
import { CreateBatch } from "../../../components/EditOnboarding/CattleMap/CreateBatch/index";
import { CreateFarm } from "../../../components/EditOnboarding/PropertiesArea/Farm";
import { ReactNode, useEffect, useState } from "react";
import { useFilter } from "../../../hooks/useFilter";
import { MicroArea } from "../../../components/EditOnboarding/PropertiesArea/MicroArea";
import { EditAreas } from "./EditAreas/Index";
import { Loading } from "../../../components/Loading/Index";
import { AnimalCategory } from "../../Onboarding/Register/AnimalCategory/Index";
import { CreateProfiles } from "../../Onboarding/Register/CreateProfiles/Index";
import { EditValuesProvider } from "../../../contexts/EditValuesContext";
import { SubSection } from "./SubSection";
import { CreateBirthGoals } from "../../Onboarding/Register/CreateBirthGoals/Index";
import { CreateDeathGoals } from "../../Onboarding/Register/CreateDeathGoals/Index";
import { ViewTables } from "../../../components/ViewTables/Index";
import { CreateProducts } from "../../../components/Tasks/CreateProducts";
import { ButtonOnlyTitle } from "../../../components/Buttons/ButtonOnlyTitle/Index";
import CreateEvaluator from "../../../components/Tasks/CreateEvaluator/Modal/CreateEvaluator";
import { useTaskCreation } from "../../../hooks/useTaskCreation";
import { ListEvaluators } from "../../../components/Tasks/CreateEvaluator/ListEvaluators";
import StartEvaluation from "../../../components/Tasks/CreateEvaluator/Modal/StartEvaluation";
import axios from '../../../services/axios'
import { useNavigate } from "react-router-dom";
import Constants from "../../../constants/Index";
import { IOptionsSideBarLinks } from "../../../contexts/FilterContext";

export function ShowData() {
  const {
    filterSelected,
    setFilterSelected,
    idSelected,
    optionForm,
    setOptionForm,
  } = useEditOnboarding();
  const navigate = useNavigate();
  const { systemManager, setSystemManager, sideBarOptionSelected, setModalCalendarFilter } = useFilter();
  const { setModalCreateEvaluator, setModalStartEvaluator, modalStartEvaluator } = useTaskCreation();

  const [titlePage] = useState(new Map());
  const [loadingState, setLoadingState] = useState<boolean>(false);

  const [customComponent, setCustomComponent] = useState<ReactNode | undefined>(undefined);
  const [componentSearchable, setComponentSearchable] = useState<boolean>(true);
  const [createComponent, setCreateComponent] = useState<boolean>(true);

  const tables = ['PRODUCTS', 'PASTURE_EVALUATION', 'TASK_EVALUATION']

  useEffect(() => {
    if (systemManager == "PRODUCTS") setFilterSelected("create_products");
    if (systemManager == "PROPERTIES_AREAS") setFilterSelected("farms");
    if (systemManager == "CREATE_BATCH") setFilterSelected("batches");
    if (systemManager == "CATTLE_MAP") setFilterSelected("animal_category");
    setOptionForm(undefined)
  }, [sideBarOptionSelected]);

  useEffect(() => {
    titlePage.set("TASK_EVALUATION", "Monitoramento de tarefas");
    titlePage.set("CREATE_PRODUCTS", "Cadastro de produto");
    titlePage.set("PRODUCTS", "Produtos");
    titlePage.set("PASTURE_EVALUATION", "Avaliação de pastagem");
    titlePage.set("MANAGER_EVALUATORS", "Gerenciar avaliadores");
    titlePage.set("PROPERTIES_AREAS", "Propriedades e Áreas");
    titlePage.set("PROFILES", "Perfis");
    titlePage.set("CREATE_BATCH", "Criação de Lotes");
    titlePage.set("CATTLE_MAP", "Categorias e Pesos");
  }, []);

  const [optionSelected, setOptionSelected] = useState<null | string>(null);
  useEffect(() => {
    if (sideBarOptionSelected == "MANAGER_SYSTEM") {
      setOptionSelected(systemManager)
    } else {
      setOptionSelected(sideBarOptionSelected)
    }
  }, [systemManager, sideBarOptionSelected]);

  useEffect(() => {
    switch (filterSelected) {
      case "animal_category":
        setCustomComponent(() => <AnimalCategory onlyRead />);
        setComponentSearchable(false);
        setCreateComponent(false);
        break;
      case 'goals_birth':
        setCustomComponent(() => <CreateBirthGoals onlyRead />);
        setComponentSearchable(false);
        setCreateComponent(false);
        break;
      case 'goals_death':
        setCustomComponent(() => <CreateDeathGoals onlyRead />);
        setComponentSearchable(false);
        setCreateComponent(false);
        break;
      default:
        if (sideBarOptionSelected !== 'PROFILES') {
          setCustomComponent(() => undefined);
          setComponentSearchable(true);
          setCreateComponent(true);
        }
        break;
    }
  }, [filterSelected]);

  const [haveEvaluator, setHaveEvaluator] = useState(false);

  useEffect(() => {
    axios.get("users/user/evaluator")
      .then(({ data }) => {
        setHaveEvaluator(data.filter((el: any) => el.status == "ACTIVE").length > 0)
      })
  }, [, modalStartEvaluator, optionForm]);

  const optionsSideBarLinksValues: IOptionsSideBarLinks[] = [
    'MAPA',
    'PASTURE_EVALUATION',
    'MANAGER_SYSTEM',
    'PROFILES',
    'LOGOUT'
  ];

  return (
    <>
      <div className="templateData">
        <Loading visible={loadingState} />

        {/* Header */}
        <>
          <div className="container__category">
            <div className="d-flex flex-row">
              {
                optionSelected && !optionsSideBarLinksValues.includes(optionSelected as IOptionsSideBarLinks) ?
                  <button
                    className={"back__button"}
                    onClick={() => {
                      if (optionSelected == 'MANAGER_EVALUATORS') {
                        setOptionSelected("PASTURE_EVALUATION")
                        setOptionForm(undefined)
                      } else {
                        optionForm ?
                          setOptionForm(undefined)
                          :
                          setSystemManager(null)
                      }
                    }}
                  >
                    <BackIcon />
                  </button>
                  : <></>
              }

              <h2>
                {titlePage.get(optionSelected)}
              </h2>
            </div>

            {!optionForm && createComponent && optionSelected && optionSelected != "PROFILES" ?
              (
                <>
                  {optionSelected === "PASTURE_EVALUATION" ?
                    <div style={{ display: "flex", gap: 10 }}>
                      <div style={{ width: 295 }}>
                        <ButtonOnlyTitle
                          title={"Iniciar avaliação"}
                          theme={"info"}
                          type="button"
                          onClick={() => setModalStartEvaluator(true)}
                          disabled={!haveEvaluator}
                        />
                      </div>
                      <button
                        className={"add__button"}
                        onClick={() => setModalCalendarFilter(true)}
                      >
                        <CalendarIcon />
                      </button>
                      <button
                        className={"add__button"}
                        onClick={() => {
                          setOptionSelected("MANAGER_EVALUATORS")
                          setOptionForm("create_evaluator")
                        }}
                      >
                        <UsersIcon />
                      </button>
                      <button
                        className={"add__button"}
                        onClick={() => {
                          navigate(Constants.EVALUATION_REPORT)
                        }}
                      >
                        <DownloadIcon />
                      </button>
                    </div>
                    :
                    <button
                      className={"add__button"}
                      onClick={() => setOptionForm(filterSelected)}
                    >
                      <PlusIcon />
                    </button>
                  }
                </>
              )
              :
              optionForm == "create_evaluator" ?
                <button
                  className={"add__button"}
                  onClick={() => setModalCreateEvaluator(true)}
                >
                  <PlusIcon />
                </button>
                : ""
            }

          </div>
        </>

        <div className="delimiter" style={{ marginBottom: 20 }} />

        {/* Header */}

        {/* Edição */}
        <section className={optionForm ? "body__editOnboarding" : ""}>
          {optionForm == "farms" ? <CreateFarm /> : <></>}
          {optionForm == "areas" ? (
            <EditAreas
              onSuccessEditingSave={() => setOptionForm(undefined)}
              onSuccessCreate={() => setOptionForm(undefined)}
              onCancelButton={() => setOptionForm(undefined)}
              onDeleteEvent={() => setOptionForm(undefined)}
              setLoadingState={setLoadingState}
              editAreaFromId={idSelected}
            />
          ) : (
            <></>
          )}
          {optionForm == "micro-areas" ? <MicroArea /> : <></>}
          {optionForm == "batches" ? <CreateBatch /> : <></>}
          {optionForm == "create_products" ? <CreateProducts /> : <></>}
          {optionForm == "create_evaluator" ? <ListEvaluators /> : <></>}
        </section>
        {/* Edição */}

        {/* Cards */}
        {!optionForm ?
          optionSelected != "PROFILES" ? (
            tables.includes(optionSelected || "") ?
              <ViewTables />
              :
              <ViewCards disabledSubmit hideControllsSubmit searchable={componentSearchable} children={customComponent} />
          ) : (
            <EditValuesProvider>
              <SubSection items={[{ element: <CreateProfiles onlyRead={true} /> }]} defaultValue={0} />
            </EditValuesProvider>
          )
          : <></>
        }
        {/* Cards */}
      </div>

      {/* Modals */}
      <CreateEvaluator />
      <StartEvaluation />
      {/* Modals */}
    </>
  );
}
